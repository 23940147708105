/*******************************
         Site Overrides
*******************************/
.ui.form input:not([type]), 
.ui.form input[type="date"], 
.ui.form input[type="datetime-local"], 
.ui.form input[type="email"], 
.ui.form input[type="number"], 
.ui.form input[type="password"], 
.ui.form input[type="search"], 
.ui.form input[type="tel"], 
.ui.form input[type="time"], 
.ui.form input[type="text"], 
.ui.form input[type="file"], 
.ui.form input[type="url"] {
    min-width: 5rem;
}

.ui.form textarea {
    min-height: 38px;
}

.ui.form .field > label {
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 0.35714286rem;
}

.ui.form > .ui.pointing.label {
    border: 1px solid @errorColor;
    border-radius: 4px;
}

/* Scrollbar part */
.ui.form textarea::-webkit-scrollbar {
    width: 14px !important;
    cursor: default;
}

.ui.form textarea::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-right: 5px solid transparent !important;
    border-left: 5px solid transparent !important;
    background-color: @primaryColor !important;
    background-clip: padding-box !important;
}

.ui.form textarea::-webkit-scrollbar-track {
    background-color: transparent !important;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: default;
}