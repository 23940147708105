/*******************************
        User Overrides
*******************************/


.ui.multiple.search.selection.dropdown > input.search {
    border-radius: 1.35rem;
    padding-left: 3px;
}

.ui.multiple.search.dropdown > .text{
    padding-left: 10px;
}

/* Scrollbar part */
.ui.selection.active.dropdown .menu::-webkit-scrollbar {
    width: 14px !important;
}

.ui.multiple.dropdown {
    padding: 0.22619048em 2.1em 0.22619048em 0.5em;
}

.ui.selection.active.dropdown .menu::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-right: 5px solid transparent !important;
    border-left: 5px solid transparent !important;
    background-color: @primaryColor !important;
    background-clip: padding-box !important;
}

.ui.selection.active.dropdown .menu::-webkit-scrollbar-thumb {
    background-color: @primaryColor !important;
}

.ui.selection.active.dropdown .menu::-webkit-scrollbar-track {
    background-color: transparent !important;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    margin-top: 0px;
    margin-bottom: 15px;
}
.ui.selection.upward.active.dropdown .menu::-webkit-scrollbar-track {
    background-color: transparent !important;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    margin-top: 15px;
    margin-bottom: 0px;
}

/* Icon part */
i.dropdown.icon:not(.clear) {
    background: url(/img/arrow.svg) no-repeat 6px;
    margin: -2px 0 0 !important;
}

    i.dropdown.icon:not(.clear):before {
        content: '' !important;
    }

.ui.dropdown.active > i.dropdown.icon:not(.clear) {
    -ms-transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.ui.dropdown input.search{
    border-radius: 1.35rem;
}