/*******************************
         Site Overrides
*******************************/

.ui.icon.input > .input-label-icon {
    font-family: 'ClearSans', Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #202020;
}

.ui.icon.input.disabled > .input-label-icon, .field.disabled .input-label-icon {
    opacity: 0.3;
}

.error .ui.icon.input > .icon.input-label-icon,
.ui.icon.input.error > .icon.input-label-icon{
    color: #990202;
}

.ui.input.error textarea, .ui.input.error input:not([type]), 
.ui.input.error input[type="date"], 
.ui.input.error input[type="datetime-local"], 
.ui.input.error input[type="email"], 
.ui.input.error input[type="number"], 
.ui.input.error input[type="password"], 
.ui.input.error input[type="search"], 
.ui.input.error input[type="tel"], 
.ui.input.error input[type="time"], 
.ui.input.error input[type="text"], 
.ui.input.error input[type="file"], 
.ui.input.error input[type="url"] {
    background: #FFF6F6;
    border-color: #E0B4B4;
    color: #9F3A38;
}

.field.disabled label {
    color: @disabledLabelColor;
}

.field.disabled input {
    opacity: @disabledOpacity;
}