/*******************************
        User Overrides
*******************************/

.ui.popup {
    border: 1px solid @primaryBorderColor;
    border-radius: 4px;
}

.ui.popup::before {
    box-shadow: 1px 1px 0 0 @primaryBorderColor;
}

.ui.popup.bottom:before,
.ui.popup.bottom.left:before,
.ui.popup.bottom.right:before,
.ui.popup.bottom.center:before {
    box-shadow: -1px -1px 0 0 @primaryBorderColor;
}

.ui.popup.left.center:before {
    box-shadow: 1px -1px 0 0 @primaryBorderColor;
}

.ui.popup.right.center:before {
    box-shadow: -1px 1px 0 0 @primaryBorderColor;
}

.ui.popup.error {
    border-color: @errorBorderColor;
}

.ui.popup.error::before {
    box-shadow: 1px 1px 0 0 @errorBorderColor;
}

.ui.popup.error.bottom:before,
.ui.popup.error.bottom.left:before,
.ui.popup.error.bottom.right:before,
.ui.popup.error.bottom.center:before {
    box-shadow: -1px -1px 0 0 @errorBorderColor;
}

.ui.popup.error.left.center:before {
    box-shadow: 1px -1px 0 0 @errorBorderColor;
}

.ui.popup.error.right.center:before {
    box-shadow: -1px 1px 0 0 @errorBorderColor;
}