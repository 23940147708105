/*******************************
         Site Overrides
*******************************/

.ui.table {
    border-collapse: collapse;
    border: 1px solid #C4D6E9;
    border-radius: 0;
}

.ui.table thead tr:first-child > th:last-child,
.ui.table thead tr:first-child > th:first-child,
.ui.table thead tr:last-child > th:first-child,
.ui.table thead tr:last-child > th:first-child {
    border-radius: 0;
}

.ui.table thead th,
.ui.table tbody td {
    padding: 1rem;
    border: none;
}

.ui.table thead {
    border: none;
    border-bottom: 1px solid #135CA9;
}

.ui.table th,
.ui.table td,
.ui.table tr:last-child,
.ui.table tbody tr:last-child,
.ui.table tbody tr:last-child td {
    border: none;
}

.ui.table tbody tr:not(:last-child) {
    border: none;
    border-bottom: 1px solid #C4D6E9;
}