/*******************************
         Site Overrides
*******************************/
.ui.button {
    border: 1px solid @secondaryColor;
  }
.ui.buttons .button:first-child{
    border-left: 1px solid @secondaryColor;
}

.ui.primary.button {
    background-color: white;
    border: 1px solid @primaryColor;
}
.ui.primary.buttons .button:hover,
.ui.primary.button:hover {
  background-color: @primaryColorHover;
  color: @primaryTextColorHover;
  text-shadow: @primaryTextShadow;
}
.ui.primary.buttons .button:focus,
.ui.primary.button:focus {
  background-color: @primaryColorFocus;
  color: @primaryTextColorHover;
  text-shadow: @primaryTextShadow;
}
.ui.primary.buttons .button:active,
.ui.primary.button:active {
  background-color: @primaryColorDown;
  color: @primaryTextColorHover;
  text-shadow: @primaryTextShadow;
}
.ui.primary.buttons .active.button,
.ui.primary.buttons .active.button:active,
.ui.primary.active.button,
.ui.primary.button .active.button:active {
  background-color: @primaryColorActive;
  color: @primaryTextColorHover;
  text-shadow: @primaryTextShadow;
}
.ui.buttons .button.primary:first-child{
    border-left: 1px solid @primaryColor;
}


.ui.negative.button {
    background-color: white;
    border: 1px solid @negativeColor;
}
.ui.negative.buttons .button:hover,
.ui.negative.button:hover {
  background-color: @negativeColorHover;
  color: @negativeTextColorHover;
  text-shadow: @negativeTextShadow;
}
.ui.negative.buttons .button:focus,
.ui.negative.button:focus {
  background-color: @negativeColorFocus;
  color: @negativeTextColorHover;
  text-shadow: @negativeTextShadow;
}
.ui.negative.buttons .button:active,
.ui.negative.button:active {
  background-color: @negativeColorDown;
  color: @negativeTextColorHover;
  text-shadow: @negativeTextShadow;
}
.ui.negative.buttons .active.button,
.ui.negative.buttons .active.button:active,
.ui.negative.active.button,
.ui.negative.button .active.button:active {
  background-color: @negativeColorActive;
  color: @negativeTextColorHover;
  text-shadow: @negativeTextShadow;
}
.ui.buttons .button.negative:first-child{
    border-left: 1px solid @negativeColor;
}

.ui.button.underlined {
  color: @underlinedTextColor;
  text-decoration-line: underline;

  border: none;
  background: none;
  padding: 0;
  padding: 0;
}

.ui.button.underlined:hover,
.ui.button.underlined:focus {
  color: @underlinedTextColorHover;
  background: none;
  border: none;
  padding: 0;
}