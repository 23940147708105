/*******************************
         Site Overrides
*******************************/
.ui.modal{
    margin-bottom: 100px;
}

.ui.modal.request > .content{
    background-color: @poolingBackground;
}

.ui.modal.request > .actions{
    background-color: #fff;
}

.ui.modal:not(.scrolling) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}